/* eslint-disable react-hooks/exhaustive-deps */
import {
  iProductContextProps,
  iProductCategoryPage,
  iProductCompany,
} from "src/interfaces/products";
import { createContext, useMemo, useState } from "react";
import ProductCompany from "src/models/ProductCompany";
import useCompany from "src/hooks/useCompany";
import productCompanyConsumer from "src/services/productCompany";
import { toast } from "react-toastify";
import { iPage } from "src/interfaces/layout";

const ProductContext = createContext<iProductContextProps>(
  {} as iProductContextProps
);

export function ProductCompanyProvider({ children }: { children: any }) {
  const { companySelected } = useCompany();

  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<iProductCategoryPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });

  const [pageSelect, setPageSelect] = useState<iProductCategoryPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });

  const [productSelected, setProductSelected] = useState<
    iProductCompany | null | any
  >(null);

  const [productId, setProductId] = useState<string>("select");
  const [productSelect, setProductSelect] = useState<any>(null);
  const [codeProductCompany, setCodeProductCompany] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [unit, setUnit] = useState<string>("");
  const [category, setCategory] = useState<string>("select");
  const [highCubage, setHighCubage] = useState<boolean>(false);
  const [categoryProduct, setCategoryProduct] = useState<any>(null);
  const [sku, setSku] = useState<string>("");
  const [factorCash, setFactorCash] = useState<number>(0);
  const [active, setActive] = useState<boolean>(false);
  const [defaultProd, setDefaultProd] = useState<boolean>(false);
  const [products, setProducts] = useState<Array<iProductCompany>>([]);
  const [productsToSearch, setProductsToSearch] = useState<Array<any>>([]);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value),
    });
  };

  const getMoreProductsToSearch = async (idCompany: string, page: iPage) => {
    try {
      setLoading(true);

      const response = await productCompanyConsumer.get(idCompany, page);

      if (response.status !== 200) throw response;

      const data = response.data.items.map((item: any) => {
        return {
          id: item?.id,
          code: item?.codigoProdutoEmpresa,
          label: item?.descricao,
        };
      });

      setProductsToSearch([...productsToSearch, ...data]);
    } catch {
      setProductsToSearch([...productsToSearch]);
    } finally {
      setLoading(false);
    }
  };

  const getProducts = async (
    idCompany: string,
    pageNumber: number,
    rowsPerPage: number,
    producVinculate?: boolean
  ) => {
    try {
      setLoading(true);

      const response = await productCompanyConsumer.get(
        idCompany,
        {
          ...page,
          page: pageNumber,
          rowsPerPage: rowsPerPage,
        },
        codeProductCompany.trim(),
        description.trim(),
        producVinculate ?? false
      );

      const data = response.data.items
        .sort((a: any, b: any) => a.descricao.localeCompare(b.descricao))
        .map((item: any) => ProductCompany.adapterToClass(item));

      if (page.total === 0 && response.data.totalItems > 0) {
        setPage({
          ...page,
          page: pageNumber,
          rowsPerPage: rowsPerPage,
          total: response.data.totalItems,
        });
      }

      setProducts(data);
      setProductsToSearch(
        response?.data?.items.map((item: any) => {
          return {
            id: item?.id,
            code: item?.codigoProdutoEmpresa,
            label: item?.descricao,
            unidade: item?.unidade,
            ...item,
          };
        })
      );
    } catch {
      toast.error(
        "Ops... identificamos um erro ao buscar os produtos cadastrados!"
      );
    } finally {
      setLoading(false);
    }
  };

  const toFillUSerDataOnForm = (data: iProductCompany | null) => {
    setProductSelected(data ?? null);
    setProductSelect({
      id: data?.productId ?? "",
      label: data?.produtoPrincipalDescricao ?? "",
    });
    setProductId(data?.productId ?? "");
    setCodeProductCompany(data?.codeProductCompany ?? "");
    setDescription(data?.description ?? "");
    setUnit(data?.unit ?? "");
    setCategory(data?.category ?? "");
    setHighCubage(data?.highCubage ?? false);
    setCategoryProduct({
      id: data?.categoryProductId ?? "",
      label: data?.categoryProduct ?? "",
    });
    setSku(data?.sku ?? "");
    setFactorCash(data?.factorCash ?? 0);
    setActive(data?.active ?? false);
    setDefaultProd(data?.defaultProd ?? false);
  };

  const handleSalveNewProduct = async () => {
    try {
      setLoading(true);

      const product = new ProductCompany(
        null,
        productId,
        companySelected?.id,
        codeProductCompany ?? "",
        description ?? "",
        unit ?? "",
        category ?? "",
        highCubage,
        categoryProduct?.id ?? "",
        sku ?? "",
        factorCash ?? 0,
        active ?? false,
        defaultProd ?? false
      );

      const response = await productCompanyConsumer.created(product);

      if (response.status !== 200 && response.status !== 201) throw response;

      toast.success("Novo produto cadastrada com sucesso!");

      product.id = response.data.id;
      const newProducts = [product, ...products];
      setProducts(newProducts);
      toFillUSerDataOnForm(null);
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ??
          "Ops... tivemos um problema ao cadastrar o novo produto!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateProduct = async () => {
    try {
      setLoading(true);

      const product = new ProductCompany(
        productSelected?.id,
        productId,
        companySelected?.id,
        codeProductCompany ?? "",
        description ?? "",
        unit ?? "",
        category ?? "",
        highCubage,
        categoryProduct?.id ?? "",
        sku ?? "",
        factorCash ?? 0,
        active ?? false,
        defaultProd ?? false
      );

      const response = await productCompanyConsumer.updated(product);

      if (response.status !== 200 && response.status !== 201) throw response;

      toast.success("Produto alterada com sucesso!");

      const changeProduct = products.filter((e) => e.id !== product.id);
      const newProducts = [product, ...changeProduct];
      setProducts(newProducts);
      toFillUSerDataOnForm(null);
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ??
          "Ops... tivemos um problema ao alterar o produto!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteProduct = async (product: iProductCompany) => {
    try {
      setLoading(true);

      const response = await productCompanyConsumer.deleted(`${product?.id}`);

      if (response.status !== 200) throw response;

      toast.success("Produto deletado com sucesso!");

      setPage({
        ...page,
        page: 0,
      });

      const newProducts = products.filter((e) => e.id !== product.id);
      setProducts(newProducts);
    } catch {
      toast.error("Erro ao deletar o produto!");
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      products,
      setProducts,
      productSelected,
      setProductSelected,
      getProducts,
      loading,
      page,
      handleChangePage,
      handleChangeRowsPerPage,
      handleSalveNewProduct,
      toFillUSerDataOnForm,
      handleUpdateProduct,
      handleDeleteProduct,
      productId,
      setProductId,
      codeProductCompany,
      setCodeProductCompany,
      description,
      setDescription,
      unit,
      setUnit,
      category,
      setCategory,
      highCubage,
      setHighCubage,
      categoryProduct,
      setCategoryProduct,
      sku,
      setSku,
      factorCash,
      setFactorCash,
      active,
      setActive,
      defaultProd,
      setDefaultProd,
      productsToSearch,
      setProductsToSearch,
      productSelect,
      setProductSelect,
      getMoreProductsToSearch,
      pageSelect,
      setPageSelect,
      setPage,
    };
  }, [
    products,
    productSelected,
    loading,
    page,
    productId,
    codeProductCompany,
    description,
    unit,
    category,
    highCubage,
    categoryProduct,
    sku,
    factorCash,
    active,
    defaultProd,
    productsToSearch,
    productSelect,
    pageSelect,
  ]);

  return (
    <ProductContext.Provider value={contextValue}>
      {children}
    </ProductContext.Provider>
  );
}

export default ProductContext;
