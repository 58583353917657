/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { maskCnpj } from "src/functions/text";
import { iPage } from "src/interfaces/layout";
import ShoppingProcessDemandClientConsumer from "src/services/shoppingProcessDemandClient";
import { utils, writeFile } from "xlsx";

const ShoppingProcessDemandClientContext = createContext<any>({} as any);

export function ShoppingProcessDemandClientProvider({
  children,
}: {
  children: any;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [demand, setDemand] = useState<any>(null);
  const [page, setPage] = useState<iPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const getShoppingProcessDemandClient = async (
    shoppingProccessId: string,
    companyId: string
  ) => {
    setIsError(false);
    setLoading(true);
    try {
      const response = await ShoppingProcessDemandClientConsumer.get(
        page,
        shoppingProccessId,
        companyId
      );

      if (response.status !== 200) throw response;

      const startItems = response.data?.processoCompraDemandaItens?.map(
        (item: any, index: number) => {
          return {
            codigoCliente: item.produtoCodigo,
            descricaoCliente: item.produtoDescricao,
            possuiInteresse: true,
          };
        }
      );

      setDemand({
        ...response.data,
        cnpj: maskCnpj(response.data.cnpj),
      });

      return startItems;
    } catch (e) {
      setIsError(true);
      toast.error("Ops... identificamos um erro ao buscar as demandas!");
    } finally {
      setLoading(false);
    }
  };

  const gererateCSV = () => {
    const header_ittens = {
      comlumn_1: "Cód",
      comlumn_2: "Descrição",
      comlumn_3: "Cód Cliente",
      comlumn_4: "Descrição Cliente",
      comlumn_5: "Quantidade",
      comlumn_6: "Valor Últ. Compra (R$)",
      comlumn_7: "Valor Alvo (R$)",
      comlumn_8: "Não Possui Interesse ?",
    };

    const header_ittens_indication = {
      comlumn_1: "Cód",
      comlumn_2: "Descrição",
      comlumn_5: "Quantidade",
      comlumn_6: "Valor Últ. Compra (R$)",
      comlumn_7: "Valor Alvo (R$)",
    };

    const sheet1Data = [objectToArray(header_ittens)];
    const sheet2Data = [objectToArray(header_ittens_indication)];

    const ws1 = utils.aoa_to_sheet(sheet1Data);
    const ws2 = utils.aoa_to_sheet(sheet2Data);

    // Definindo a largura das colunas para ws1
    ws1["!cols"] = [
      { wch: 10 },
      { wch: 30 },
      { wch: 15 },
      { wch: 30 },
      { wch: 12 },
      { wch: 20 },
      { wch: 15 },
      { wch: 25 },
    ];

    // Definindo a largura das colunas para ws2
    ws2["!cols"] = [
      { wch: 10 },
      { wch: 30 },
      { wch: 12 },
      { wch: 20 },
      { wch: 15 },
    ];

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws1, "Itens");
    utils.book_append_sheet(wb, ws2, "Indicação de Itens");

    writeFile(wb, "planilha_itens.xls");
    console.log(demand.processoCompraDemandaItens);
  };

  const objectToArray = (obj: any) => Object.values(obj);

  const contextValue = useMemo(() => {
    return {
      page,
      getShoppingProcessDemandClient,
      loading,
      setLoading,
      handleChangePage,
      handleChangeRowsPerPage,
      demand,
      setDemand,
      isError,
      setIsError,
      gererateCSV,
    };
  }, [page, loading, demand, isError]);

  return (
    <ShoppingProcessDemandClientContext.Provider value={contextValue}>
      {children}
    </ShoppingProcessDemandClientContext.Provider>
  );
}

export default ShoppingProcessDemandClientContext;
