/* eslint-disable react-hooks/exhaustive-deps */
import {
  AppBar,
  Tabs,
  Tab,
  Toolbar,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  Grid,
  TextField,
  Button,
  Divider,
  Box,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Checkbox,
  Collapse,
  Paper,
  Container,
  Autocomplete,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { theme } from "src/theme";
import { FormValues } from "src/contexts/demandCollectionFormContext";
import useDemandCollectionForm from "src/hooks/useDemandCollectionForm";
import { useEffect } from "react";
import CircularLoading from "src/components/Loading";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useLocation } from "react-router-dom";
import useShoppingProccessDemandClient from "src/hooks/useShoppingProcessDemandClient";
import { formatCurrency } from "src/functions/text";
import useProductCompany from "src/hooks/useProductCompany";

function DemandCollectionForm() {
  const {
    isMobile,
    handleDrawerToggle,
    selectedTab,
    handleTabChange,
    drawerOpen,
    isLargeScreen,
    handleInputChange,
    handleToggle,
    open,
    handleAddSection,
    indicacaoItensSections,
    handleRemoveSection,
    send,
    setItemSections,
    itemSections,
  } = useDemandCollectionForm();

  const {
    getShoppingProcessDemandClient,
    loading,
    isError,
    demand,
    gererateCSV,
  } = useShoppingProccessDemandClient();

  const {
    getProducts,
    productsToSearch,
    setProductsToSearch,
    loading: loadingProduct,
  } = useProductCompany();

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const processocompraid = queryParams.get("processocompraid");
    const empresaid = queryParams.get("empresaid");

    if (processocompraid && empresaid) started(processocompraid, empresaid);

    return () => {
      setProductsToSearch([]);
    };
  }, []);

  const started = async (processoCompraId: string, empresaId: string) => {
    const items = await getShoppingProcessDemandClient(
      processoCompraId,
      empresaId
    );
    getProducts(empresaId, 0, 9999);

    setItemSections(items);
  };

  if (isError) {
    return (
      <Container maxWidth="sm" sx={{ textAlign: "center", mt: 10 }}>
        <ErrorOutlineIcon sx={{ fontSize: 100, color: "primary.main" }} />
        <Typography variant="h3" sx={{ color: "primary.main", mt: 2 }}>
          400 - Ops, Não existe demanda cadastrada para este processo e esta
          empresa!
        </Typography>
        <Typography variant="body1" sx={{ color: "text.secondary", mt: 2 }}>
          Ocorreu um erro inesperado. Estamos trabalhando para corrigir o
          problema.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 4 }}
          onClick={() => window.location.reload()}
        >
          Tentar Novamente
        </Button>
      </Container>
    );
  }

  const drawer = (
    <div>
      <List>
        <ListItem>
          <ListItemText primary="Demandas" />
        </ListItem>
        {/* <ListItem>
          <ListItemText primary="Logística" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Indicação Fornecedores" />
        </ListItem> */}
      </List>
    </div>
  );

  return (
    <div>
      <AppBar position="static">
        <Toolbar style={{ backgroundColor: "#fff" }}>
          {isMobile ? (
            <IconButton
              edge="start"
              color="primary"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              TabIndicatorProps={{ style: { borderRadius: 4 } }}
            >
              <Tab
                label="Demandas"
                style={{
                  color:
                    selectedTab === 0 ? "#fff" : theme.palette.primary.main,
                  backgroundColor:
                    selectedTab === 0 ? theme.palette.primary.main : "#fff",
                  borderRadius: selectedTab === 0 ? "4px 4px 0 0" : 0,
                }}
              />
              {/* <Tab
                label="Logística"
                disabled
                style={{
                  color:
                    selectedTab === 1 ? "#fff" : theme.palette.primary.main,
                  backgroundColor:
                    selectedTab === 1 ? theme.palette.primary.main : "#fff",
                  borderRadius: selectedTab === 1 ? "4px 4px 0 0" : 0,
                }}
              />
              <Tab
                label="Indicação Fornecedores"
                disabled
                style={{
                  color:
                    selectedTab === 2 ? "#fff" : theme.palette.primary.main,
                  backgroundColor:
                    selectedTab === 2 ? theme.palette.primary.main : "#fff",
                  borderRadius: selectedTab === 2 ? "4px 4px 0 0" : 0,
                }}
              /> */}
            </Tabs>
          )}
        </Toolbar>
      </AppBar>

      <Divider
        style={{
          backgroundColor: theme.palette.primary.main,
        }}
      />

      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
        {drawer}
      </Drawer>

      {loading || loadingProduct ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ marginTop: "5%" }}
        >
          <CircularLoading />
        </Box>
      ) : (
        <form onSubmit={(e) => send(e)}>
          <div style={{ padding: theme.spacing(2) }}>
            <Typography variant="h6" gutterBottom>
              Descrição Processo
            </Typography>

            <Grid container spacing={2}>
              {[
                { label: "Descrição do Processo", name: "descricao" },
                { label: "Número Processo", name: "numero" },
                { label: "CNPJ", name: "cnpj" },
                { label: "Empresa", name: "razaoSocial" },
                { label: "Comprador Responsável", name: "nomeComprador" },
                { label: "E-mail", name: "emailComprador" },
                { label: "Rede", name: "rede" },
                { label: "Telefone", name: "telefoneComprador" },
                { label: "Carta Convite", name: "cartaConvite" },
              ]?.map((field, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box display="flex" alignItems="center">
                    {isLargeScreen && (
                      <Grid md={4}>
                        <Typography
                          variant="body2"
                          style={{ marginRight: theme.spacing(1) }}
                        >
                          {field.label}
                        </Typography>
                      </Grid>
                    )}
                    <Grid xs={12} md={8}>
                      <TextField
                        placeholder={field.label}
                        fullWidth
                        size="small"
                        name={field.name}
                        disabled
                        value={demand && demand[field.name as keyof FormValues]}
                        onChange={(e) => handleInputChange(e, index, "")}
                        InputLabelProps={
                          isLargeScreen
                            ? {
                                shrink: true,
                                style: {
                                  position: "relative",
                                  marginBottom: 0,
                                },
                              }
                            : undefined
                        }
                      />
                    </Grid>
                  </Box>
                </Grid>
              ))}
            </Grid>

            <Divider
              style={{
                marginTop: theme.spacing(4),
                backgroundColor: theme.palette.primary.main,
              }}
            />

            <Box marginTop={theme.spacing(2)} marginBottom={theme.spacing(2)}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginTop={theme.spacing(2)}
                marginBottom={theme.spacing(2)}
              >
                <Typography variant="h6">Itens</Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ width: "20%" }}
                >
                  <Button
                    variant="contained"
                    startIcon={<UploadIcon />}
                    size="small"
                  >
                    Upload
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    size="small"
                    color="secondary"
                    onClick={gererateCSV}
                  >
                    Download Modelo
                  </Button>
                </Box>
              </Box>
              <Box>
                {!isMobile ? (
                  <Table component={Paper}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Cód.</TableCell>
                        <TableCell>Descrição</TableCell>
                        <TableCell>Cód. Cliente</TableCell>
                        <TableCell>Descrição Cliente</TableCell>
                        <TableCell>
                          Quantidade <span style={{ color: "red" }}>*</span>
                        </TableCell>
                        <TableCell>
                          Valor Últ. Compra (R$){" "}
                          <span style={{ color: "red" }}>*</span>
                        </TableCell>
                        <TableCell>
                          Valor Alvo (R$){" "}
                          <span style={{ color: "red" }}>*</span>
                        </TableCell>
                        <TableCell>Não Possui Interesse</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {demand?.processoCompraDemandaItens?.map(
                        (item: any, index: any) => (
                          <TableRow key={index}>
                            <TableCell>
                              {item?.produtoCodigo ?? "Não informado"}
                            </TableCell>
                            <TableCell>
                              {item?.produtoDescricao ?? "Não informado"}
                            </TableCell>
                            <TableCell
                              sx={{
                                width: "250px",
                                maxWidth: "400px",
                                minWidth: "250px",
                              }}
                            >
                              <Autocomplete
                                disablePortal
                                options={productsToSearch}
                                getOptionLabel={(option) => option.code}
                                size="small"
                                onChange={(e, value) =>
                                  handleInputChange(
                                    {
                                      target: {
                                        name: "codigoCliente",
                                        value: value.code,
                                      },
                                    },
                                    index,
                                    "item"
                                  )
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Selecione"
                                    size="small"
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                width: "250px",
                                maxWidth: "400px",
                                minWidth: "250px",
                              }}
                            >
                              <Autocomplete
                                disablePortal
                                id="show_select_administrator_company"
                                options={productsToSearch}
                                getOptionLabel={(option) => option.label}
                                size="small"
                                onChange={(e, value) =>
                                  handleInputChange(
                                    {
                                      target: {
                                        name: "descricaoCliente",
                                        value: value.label,
                                      },
                                    },
                                    index,
                                    "item"
                                  )
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Selecione"
                                    size="small"
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                placeholder="Quantidade"
                                fullWidth
                                size="small"
                                name="quantidade"
                                required={
                                  itemSections &&
                                  itemSections[index]?.possuiInteresse
                                }
                                onChange={(e) =>
                                  handleInputChange(e, index, "item")
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                placeholder="Valor Últ. Compra (R$)"
                                fullWidth
                                size="small"
                                name="valorUnitario"
                                required={
                                  itemSections &&
                                  itemSections[index]?.possuiInteresse
                                }
                                onChange={(e) => {
                                  e = formatCurrency(e);
                                  handleInputChange(e, index, "item");
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                placeholder="Valor Alvo (R$)"
                                fullWidth
                                size="small"
                                name="valorAlvo"
                                required={
                                  itemSections &&
                                  itemSections[index]?.possuiInteresse
                                }
                                onChange={(e) => {
                                  e = formatCurrency(e);
                                  handleInputChange(e, index, "item");
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <Checkbox
                                name="possuiInteresse"
                                onChange={(e) =>
                                  handleInputChange(e, index, "item")
                                }
                              />
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                ) : (
                  <Box>
                    {demand?.processoCompraDemandaItens?.map(
                      (item: any, index: any) => (
                        <Paper key={index} sx={{ marginBottom: 2 }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: 2,
                            }}
                          >
                            <Typography variant="subtitle1">
                              {`Cód.: ${
                                item?.produtoCodigo ?? "Não informado"
                              } - Descrição: ${
                                item?.produtoDescricao ?? "Não informado"
                              }`}
                            </Typography>
                            <IconButton onClick={() => handleToggle(index)}>
                              {open[index] ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </IconButton>
                          </Box>
                          <Collapse in={open[index]}>
                            <Box sx={{ padding: 2 }}>
                              <Box>
                                <strong>Cód.:</strong>{" "}
                                {item?.produtoCodigo ?? "Não informado"}
                              </Box>
                              <Box>
                                <strong>Descrição:</strong>{" "}
                                {item?.produtoDescricao ?? "Não informado"}
                              </Box>
                              <Box>
                                <strong>Cód. Cliente:</strong>
                                <Autocomplete
                                  disablePortal
                                  id="show_select_administrator_company"
                                  options={productsToSearch}
                                  getOptionLabel={(option) => option.code}
                                  size="small"
                                  onChange={(e, value) =>
                                    handleInputChange(
                                      {
                                        target: {
                                          name: "codigoCliente",
                                          value: value.code,
                                        },
                                      },
                                      index,
                                      "item"
                                    )
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Selecione"
                                      size="small"
                                    />
                                  )}
                                />
                              </Box>
                              <Box>
                                <strong>Descrição Cliente:</strong>
                                <Autocomplete
                                  disablePortal
                                  id="show_select_administrator_company"
                                  options={productsToSearch}
                                  getOptionLabel={(option) => option.label}
                                  size="small"
                                  onChange={(e, value) =>
                                    handleInputChange(
                                      {
                                        target: {
                                          name: "descricaoCliente",
                                          value: value.label,
                                        },
                                      },
                                      index,
                                      "item"
                                    )
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Selecione"
                                      size="small"
                                    />
                                  )}
                                />
                              </Box>
                              <Box>
                                <strong>
                                  Quantidade
                                  <span style={{ color: "red" }}>*</span>:
                                </strong>

                                <TextField
                                  placeholder="Quantidade"
                                  fullWidth
                                  size="small"
                                  name="quantidade"
                                  required={
                                    itemSections &&
                                    itemSections[index]?.possuiInteresse
                                  }
                                  onChange={(e) =>
                                    handleInputChange(e, index, "item")
                                  }
                                />
                              </Box>
                              <Box>
                                <strong>
                                  Valor Últ. Compra (R$)
                                  <span style={{ color: "red" }}>*</span>:
                                </strong>
                                <TextField
                                  placeholder="Valor Últ. Compra (R$)"
                                  fullWidth
                                  size="small"
                                  name="valorUnitario"
                                  required={
                                    itemSections &&
                                    itemSections[index]?.possuiInteresse
                                  }
                                  onChange={(e) =>
                                    handleInputChange(e, index, "item")
                                  }
                                />
                              </Box>
                              <Box>
                                <strong>
                                  Valor Alvo (R$)
                                  <span style={{ color: "red" }}>*</span>:
                                </strong>

                                <TextField
                                  placeholder="Valor Alvo (R$)"
                                  fullWidth
                                  size="small"
                                  name="valorAlvo"
                                  required={
                                    itemSections &&
                                    itemSections[index]?.possuiInteresse
                                  }
                                  onChange={(e) =>
                                    handleInputChange(e, index, "item")
                                  }
                                />
                              </Box>
                              <Box>
                                <strong>Não Possui Interesse:</strong>{" "}
                                <Checkbox
                                  name="possuiInteresse"
                                  onChange={(e) =>
                                    handleInputChange(e, index, "item")
                                  }
                                />
                              </Box>
                            </Box>
                          </Collapse>
                        </Paper>
                      )
                    )}
                  </Box>
                )}
              </Box>
            </Box>

            <Divider
              style={{
                marginTop: theme.spacing(4),
                backgroundColor: theme.palette.primary.main,
              }}
            />

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              marginTop={theme.spacing(2)}
              marginBottom={theme.spacing(2)}
            >
              <Box display="flex" alignItems="center">
                <Typography variant="h6">Indicação de Itens</Typography>
                <IconButton
                  color="primary"
                  onClick={() => handleAddSection("indicacaoItens")}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>

            {indicacaoItensSections?.map((section: any, index: any) => (
              <Box key={index} marginBottom={theme.spacing(2)} display="flex">
                <Grid container spacing={2}>
                  {[
                    { label: "Cód.", name: "codigo" },
                    { label: "Descrição", name: "descricao" },
                    { label: "Quantidade *", name: "quantidade" },
                    {
                      label: "Valor Últ. Compra (R$) *",
                      name: "valorUnitario",
                    },
                    { label: "Valor Alvo (R$) *", name: "valorAlvo" },
                  ]?.map((field, idx) => (
                    <Grid item xs={12} sm={2} key={idx}>
                      <TextField
                        placeholder={field.label}
                        fullWidth
                        size="small"
                        name={field.name}
                        value={section[field.name as keyof FormValues]}
                        onChange={(e) =>
                          handleInputChange(e, index, "indicacaoItens")
                        }
                      />
                    </Grid>
                  ))}
                  <IconButton
                    onClick={() => handleRemoveSection(index, "indicacaoItens")}
                    style={{ marginTop: theme.spacing(1) }}
                  >
                    <RemoveCircleOutlineIcon color="error" />
                  </IconButton>
                </Grid>
              </Box>
            ))}

            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              style={{ marginTop: theme.spacing(4) }}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={<CancelIcon />}
                >
                  Declinar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<CheckIcon />}
                  type="submit"
                >
                  Enviar
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
      )}
    </div>
  );
}

export default DemandCollectionForm;
