/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { iProductCategoryPage } from "src/interfaces/products";
import productCategoryGeneralConsumer from "src/services/productCategoryGeneral";
import CategoryProductGeneral from "src/models/CategoryProductGeneral";
import { iCategoryProductGeneral } from "src/interfaces/categoryProductGeneral";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";
import { iPage } from "src/interfaces/layout";

const ProductCategoryGeneralContext = createContext<any>({} as any);

export function ProductCategoryGeneralProvider({
  children,
}: {
  children: any;
}) {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [orderBy, setOrderBy] = useState<any>({ number: 2, order: false });
  const [page, setPage] = useState<iProductCategoryPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });

  const [pageSearch, setPageSearch] = useState<iProductCategoryPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });

  const [productCategoryGeneralSelected, setProductCategoryGeneralSelected] =
    useState<any>(null);

  const [productGeneralCategorys, setProductGeneralCategorys] = useState<
    Array<any>
  >([]);
  const [productGeneralCategorysToSearch, setProductGeneralCategorysToSearch] =
    useState<Array<any>>([]);

  const [code, setCode] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [descriptionSearch, setDescriptionSearch] = useState<string>("");
  const [fatherCategory, setFatherCategory] = useState<any>(null);
  const [active, setActive] = useState<boolean>(false);
  const [valueTabScreen, setValueTabScreen] = useState(0);
  const [pageGroupCategory, setPageGroupCategory] = useState<iPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });
  const [orderByGroupCategory, setOrderByGroupCategory] = useState<any>({
    number: 1,
    order: false,
  });
  const [categoriesForCategory, setCategoriesForCategory] = useState<
    Array<any>
  >([]);

  const handleChangeTabScreen = (_: React.SyntheticEvent, newValue: number) => {
    setValueTabScreen(newValue);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage });
  };

  const handleChangePageSearch = (_: unknown, newPage: number) => {
    setPageSearch({ ...page, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value),
    });
  };

  const handleChangeRowsPerPageSearch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value),
    });
  };

  const getCategories = async (
    numberSumWithPage: number = 0,
    isScreenCategoryGeneral = false,
    searchDescription = ""
  ) => {
    try {
      setLoading(true);

      const response = await productCategoryGeneralConsumer.get(
        {
          ...page,
          rowsPerPage: page.rowsPerPage + numberSumWithPage,
        },
        searchDescription.trim(),
        orderBy
      );

      if (response.status !== 200) throw response;

      const data = response.data.items.map((item: any) =>
        CategoryProductGeneral.adapterToClass(item)
      );

      if (response.data.totalItems > 0) {
        setPage({
          ...page,
          total: response.data.totalItems,
        });
      }

      setProductGeneralCategorys(data);
      if (!isScreenCategoryGeneral) {
        setProductGeneralCategorysToSearch(
          response.data.items.map((item: any) => {
            return {
              id: item?.id,
              label: item?.descricao ?? "",
              code: item?.codigo ?? "",
            };
          })
        );
      }
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ?? translations[param]["error_general"]
      );
    } finally {
      setLoading(false);
    }
  };

  const getForCategoryFather = async () => {
    setLoading(true);

    try {
      const response =
        await productCategoryGeneralConsumer.getForCategoryFather(
          pageGroupCategory,
          descriptionSearch,
          orderByGroupCategory
        );

      if (response.status !== 200) throw response;

      if (response.data.totalItems > 0) {
        setPageGroupCategory({
          ...pageGroupCategory,
          total: response.data.totalItems,
        });
      }

      const data = response.data.items.map((item: any) => {
        return {
          ...item,
          categorias: item.categorias.map((produto: any) =>
            CategoryProductGeneral.adapterToClass(produto)
          ),
        };
      });

      setCategoriesForCategory(data);

      setTimeout(() => {
        setLoading(false);
      }, 5000);
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ?? translations[param]["error_general"]
      );
      setLoading(false);
    }
  };

  const getCategoriesFather = async (numberSumWithPage: number = 0) => {
    try {
      setLoadingSearch(true);
      const response = await productCategoryGeneralConsumer.get(
        {
          ...pageSearch,
          rowsPerPage: pageSearch.rowsPerPage + numberSumWithPage,
        },
        descriptionSearch.trim()
      );

      if (response.status !== 200) throw response;

      if (pageSearch.total === 0 && response.data.totalItems > 0) {
        setPageSearch({
          ...pageSearch,
          total: response.data.totalItems,
        });
      }

      setProductGeneralCategorysToSearch(
        response.data.items.map((item: any) => {
          return {
            id: item?.id,
            label: item?.descricao ?? "",
            code: item?.codigo ?? "",
          };
        })
      );
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ?? translations[param]["error_general"]
      );
    } finally {
      setLoadingSearch(false);
    }
  };

  const handleSelect = (data: iCategoryProductGeneral | null) => {
    setProductCategoryGeneralSelected(data);
    setCode(data?.code ?? "");
    setDescription(data?.description ?? "");
    setActive(data?.active ?? false);
    setFatherCategory({
      id: data?.productCategoryId ?? "",
      code: data?.productCategoryCode ?? "",
      label: data?.productCategory ?? "",
    });
  };

  const handleSalveNewProductCategory = async () => {
    try {
      setLoading(true);

      const productCategory = new CategoryProductGeneral(
        "",
        code,
        description,
        fatherCategory.id,
        active,
        fatherCategory.label,
        fatherCategory.code
      );

      const response = await productCategoryGeneralConsumer.created(
        productCategory
      );

      if (response.status !== 200 && response.status !== 201) throw response;

      productCategory.id = response.data?.id;
      const newProductGeneralCategorys = [
        productCategory,
        ...productGeneralCategorys,
      ];

      await getForCategoryFather();
      setProductGeneralCategorys(newProductGeneralCategorys);
      toast.success(translations[param]["success_general"]);
      handleSelect(null);
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ?? translations[param]["error_general"]
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateProductCategory = async () => {
    try {
      setLoading(true);

      const productCategory = new CategoryProductGeneral(
        productCategoryGeneralSelected?.id as string,
        code,
        description,
        fatherCategory?.id ?? "",
        active
      );

      const response = await productCategoryGeneralConsumer.updated(
        productCategory
      );

      if (response.status !== 200 && response.status !== 201) throw response;

      const oldProductGeneralCategorys = productGeneralCategorys.filter(
        (e) => e?.id !== productCategory?.id
      );

      const newProductGeneralCategorys = [
        productCategory,
        ...oldProductGeneralCategorys,
      ];

      await getForCategoryFather();
      setProductGeneralCategorys(newProductGeneralCategorys);

      toast.success(translations[param]["success_general"]);
      handleSelect(null);
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ?? translations[param]["error_general"]
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteProductCategory = async (
    category: iCategoryProductGeneral
  ) => {
    try {
      setLoading(true);

      const response = await productCategoryGeneralConsumer.deleted(
        `${category?.id}`
      );

      if (response.status !== 200) throw response;

      setPage({
        ...page,
        page: 0,
      });

      const newProductGeneralCategorys = productGeneralCategorys.filter(
        (e) => e.id !== category.id
      );

      await getForCategoryFather();
      setProductGeneralCategorys(newProductGeneralCategorys);

      toast.success(translations[param]["success_general"]);
      handleSelect(null);
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ?? translations[param]["error_general"]
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSortData = (data: { id: string; order: string }) => {
    const { id, order } = data;

    // ASC: < & >
    // DESC: > & <
    if (order === "asc") {
      const dataOrder = productGeneralCategorys.sort(function (a: any, b: any) {
        if (a[id] < b[id]) {
          return -1;
        }
        if (a[id] > b[id]) {
          return 1;
        }
        return 0;
      });

      setProductGeneralCategorys([...dataOrder]);
    } else {
      const dataOrder = productGeneralCategorys.sort(function (a: any, b: any) {
        if (a[id] > b[id]) {
          return -1;
        }
        if (a[id] < b[id]) {
          return 1;
        }
        return 0;
      });

      setProductGeneralCategorys([...dataOrder]);
    }
  };

  const handleChangePageGroupCategory = (_: unknown, newPage: number) => {
    setPageGroupCategory({ ...pageGroupCategory, page: newPage });
  };

  const handleChangeRowsPerPageGroupCategory = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageGroupCategory({
      ...pageGroupCategory,
      page: 0,
      rowsPerPage: parseInt(event.target.value),
    });
  };

  const contextValue = useMemo(() => {
    return {
      productGeneralCategorys,
      setProductGeneralCategorys,
      productCategoryGeneralSelected,
      setProductCategoryGeneralSelected,
      getCategories,
      loading,
      loadingSearch,
      page,
      setPage,
      code,
      setCode,
      description,
      setDescription,
      descriptionSearch,
      setDescriptionSearch,
      fatherCategory,
      setFatherCategory,
      active,
      setActive,
      handleChangePage,
      handleChangePageSearch,
      handleChangeRowsPerPage,
      handleChangeRowsPerPageSearch,
      handleSalveNewProductCategory,
      handleSelect,
      handleUpdateProductCategory,
      handleDeleteProductCategory,
      handleSortData,
      pageSearch,
      setPageSearch,
      productGeneralCategorysToSearch,
      setProductGeneralCategorysToSearch,
      getCategoriesFather,
      orderBy,
      setOrderBy,
      valueTabScreen,
      handleChangeTabScreen,
      pageGroupCategory,
      setPageGroupCategory,
      handleChangePageGroupCategory,
      handleChangeRowsPerPageGroupCategory,
      orderByGroupCategory,
      setOrderByGroupCategory,
      categoriesForCategory,
      setCategoriesForCategory,
      getForCategoryFather,
    };
  }, [
    productGeneralCategorys,
    productCategoryGeneralSelected,
    code,
    orderBy,
    description,
    descriptionSearch,
    fatherCategory,
    active,
    loading,
    pageSearch,
    page,
    loadingSearch,
    productGeneralCategorysToSearch,
    valueTabScreen,
    pageGroupCategory,
    orderByGroupCategory,
    categoriesForCategory,
  ]);

  return (
    <ProductCategoryGeneralContext.Provider value={contextValue}>
      {children}
    </ProductCategoryGeneralContext.Provider>
  );
}

export default ProductCategoryGeneralContext;
