/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import useParticipatingCompanies from "src/hooks/useAddress copy";
import CircularLoading from "src/components/Loading";
import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { palette } from "src/theme";
import CloseIcon from "@mui/icons-material/Close";
import { ButtonDefault, ButtonInclude } from "src/components/ButtonsCommons";
import translations from "src/_i18n/translations.json";
import { tLanguage } from "src/types/language";
import { useParams } from "react-router-dom";
import { MaskStyle } from "src/styles/globalStyle";
import ReactInputMask from "react-input-mask";
import useAddress from "src/hooks/useAddress";
import TableComponent from "src/components/TableComponent";
import useCompany from "src/hooks/useCompany";
import { maskCnpj } from "src/functions/text";
import useShoppingProcess from "src/hooks/useShoppingProcess";
import { toast } from "react-toastify";

const ParticipatingCompanyAdd: React.FC = () => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const {
    loading,
    setOpenDialog,
    handleSelected,
    participatingCompaniesForAdd,
    setParticipatingCompaniesForAdd,
    handleNewSalve,
  } = useParticipatingCompanies();

  const { shoppingProcesSelect } = useShoppingProcess();

  const { statesOfCountry, getStates, loading: loadingAddress } = useAddress();

  const {
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    companysToSearchBuyer,
    getGroupCompany,
    loading: loadingCompany,
    setGroupCompany,
    groupCompany,
    getCompanyBuyer,
    nameSearch,
    setNameSearch,
    cnpjSearch,
    setCnpjSearch,
    citySearch,
    setCitySearch,
    stateSearch,
    setStateSearch,
    groupSearch,
    setGroupSearch,
  } = useCompany();

  useEffect(() => {
    setGroupCompany([]);
    setParticipatingCompaniesForAdd([]);
    getStates();
    getGroupCompany();

    getCompanyBuyer(50);
  }, []);

  const close = () => {
    setOpenDialog(false);
    handleSelected(null);
  };

  const header = [
    {
      id: "cnpj",
      label: `${translations[param]["cnpj"]}/${translations[param]["outdoor_code"]}`,
      isOrder: false,
      order: 1,
    },
    {
      id: "socialName",
      label: translations[param]["social_reason"],
      isOrder: false,
      order: 2,
    },
    {
      id: "name",
      label: translations[param]["company_name"],
      isOrder: false,
      order: 3,
    },
  ];

  useEffect(() => {}, [participatingCompaniesForAdd]);

  const add = (e: any, data: any) => {
    if (data.contatosEmpresa.length === 0) {
      toast.info("Desculpe, a empresa não possui um contato de responsável!");
      return;
    }

    const responsible = data.contatosEmpresa[0];

    const value = e.target.checked;

    const body = {
      cnpj: data.cnpj,
      processoComprasId: shoppingProcesSelect?.id,
      empresasId: data.id,
      empresasNome: data.razaoSocial,
      declinouParticipacao: false,
      responsavelId: responsible.contatoEmpresaId,
      responsavelCompradorId: responsible.contatoEmpresaId,
      responsavelNome: responsible.nome,
      email: responsible.email,
    };

    if (participatingCompaniesForAdd.length === 0) {
      setParticipatingCompaniesForAdd([body]);
      return;
    }

    if (value) {
      if (
        !participatingCompaniesForAdd.some(
          (attrSelect: any) => attrSelect.id === body.empresasId
        )
      ) {
        setParticipatingCompaniesForAdd([
          body,
          ...participatingCompaniesForAdd,
        ]);
      }

      return;
    }

    const newArray = participatingCompaniesForAdd.filter(
      (e: any) => e.empresasId !== body.empresasId
    );
    setParticipatingCompaniesForAdd(newArray);
  };

  const addList = (e: any) => {
    const value = e.target.checked;
    const items = [];

    if (!value) {
      setParticipatingCompaniesForAdd([]);
      return;
    }

    for (let index = 0; index < companysToSearchBuyer.length; index++) {
      const data = companysToSearchBuyer[index];
      if (data.contatosEmpresa.length === 0) continue;

      const responsible = data.contatosEmpresa[0];
      const body = {
        cnpj: data.cnpj,
        processoComprasId: shoppingProcesSelect?.id,
        empresasId: data.id,
        empresasNome: data.razaoSocial,
        declinouParticipacao: false,
        responsavelCompradorId: responsible.contatoEmpresaId,
        responsavelId: responsible.contatoEmpresaId,
        responsavelNome: responsible.nome,
        email: responsible.email,
      };

      items.push(body);
    }

    setParticipatingCompaniesForAdd(items);
  };

  if (loading || loadingAddress || loadingCompany) return <CircularLoading />;

  return (
    <>
      <DialogTitle id="alert-dialog-title">
        Adicionar Empresas Participantes
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={close}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider sx={{ backgroundColor: palette.primary.main }} />
      <DialogContent>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          spacing={1}
          display="flex"
        >
          <Grid item xs={0} sm={2} display="flex" flexDirection="column">
            <FormLabel sx={{ m: 1 }}>{translations[param]["cnpj"]}</FormLabel>
            <FormLabel sx={{ m: 1 }}>
              {translations[param]["social_name"]} /<br />
              {translations[param]["company_name"]}
            </FormLabel>
            <FormLabel sx={{ m: 1 }}>{translations[param]["city"]}</FormLabel>
            <FormLabel sx={{ m: 1 }}>Grupo de Compras</FormLabel>
          </Grid>
          <Grid item xs={12} sm={10} display="flex" flexDirection="column">
            <MaskStyle width="30">
              <ReactInputMask
                mask="99.999.999/9999-99"
                name="cnpj"
                placeholder={translations[param]["cnpj"]}
                style={{ margin: 1 }}
                value={cnpjSearch}
                onChange={(e) => setCnpjSearch(e.target.value)}
              />
            </MaskStyle>
            <TextField
              sx={{ my: 1 }}
              id="field"
              label={`${translations[param]["social_name"]} / ${translations[param]["company_name"]}`}
              size="small"
              fullWidth
              value={nameSearch}
              onChange={(e) => setNameSearch(e.target.value)}
            />

            <Grid display="flex" alignItems="center">
              <Grid sm={6}>
                <TextField
                  id="field"
                  label={translations[param]["city"]}
                  size="small"
                  fullWidth
                  value={citySearch}
                  onChange={(e) => setCitySearch(e.target.value)}
                />
              </Grid>
              <Grid
                sm={6}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <FormLabel sx={{ mx: 2 }}>
                  {translations[param]["state"]}
                </FormLabel>
                <Select
                  labelId="show_select_type_state"
                  id="show_select_type_state"
                  value={stateSearch}
                  fullWidth
                  size="small"
                  onChange={(event: SelectChangeEvent) => {
                    setStateSearch(event.target.value as string);
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  <MenuItem value="select" disabled>
                    {translations[param]["select"]}
                  </MenuItem>
                  <MenuItem value="">...</MenuItem>
                  {statesOfCountry?.map((item) => (
                    <MenuItem key={item.id} value={item.sigla}>
                      {item.nome}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>

            {loadingCompany ? (
              <CircularLoading />
            ) : (
              <Select
                labelId="show_select_type_state"
                id="show_select_type_state"
                value={groupSearch}
                fullWidth
                sx={{ mt: 1 }}
                size="small"
                onChange={(event: SelectChangeEvent) => {
                  setGroupSearch(event.target.value as string);
                }}
                MenuProps={{
                  disableScrollLock: true,
                }}
              >
                <MenuItem value="select" disabled>
                  {translations[param]["select"]}
                </MenuItem>
                <MenuItem value="">...</MenuItem>
                {groupCompany?.map((item: any) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.descricao}
                  </MenuItem>
                ))}
              </Select>
            )}

            <Grid sx={{ mt: 4 }} display="flex" justifyContent="end">
              <ButtonDefault
                label="Pesquisar"
                onClick={() => getCompanyBuyer(50)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          spacing={1}
          display="flex"
          sx={{ mt: 2 }}
        >
          <Grid item xs={0} sm={2} display="flex" flexDirection="column">
            <FormLabel sx={{ ml: 1 }}>
              {translations[param]["companys"]}
            </FormLabel>
          </Grid>
          <Grid item xs={12} sm={10} display="flex" flexDirection="column">
            <TableComponent
              header={header}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              isSelectAll={true}
              callSelectAll={(e) => addList(e)}
            >
              {companysToSearchBuyer?.map((data: any) => (
                <TableRow
                  hover
                  key={data?.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                >
                  <TableCell component="th" scope="row">
                    {data?.cnpj ? maskCnpj(data.cnpj) : data?.codigoExterior}
                  </TableCell>
                  <TableCell>{data?.razaoSocial}</TableCell>
                  <TableCell>{data?.nome}</TableCell>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      size="small"
                      checked={participatingCompaniesForAdd.some(
                        (attrSelect: any) => attrSelect.empresasId === data.id
                      )}
                      onChange={(e) => add(e, data)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1%" }}>
        <ButtonDefault label="Fechar" onClick={close} />
        <ButtonInclude
          label="Adicionar"
          onClick={() => handleNewSalve(`${shoppingProcesSelect?.id}`)}
        />
      </DialogActions>
    </>
  );
};

export default ParticipatingCompanyAdd;
