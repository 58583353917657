/* eslint-disable import/no-webpack-loader-syntax */
import { useState } from "react";
import Login from "src/models/Login";
import { iLogin } from "src/interfaces/login";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { localStorageStrings } from "src/constants/localStorageStings";
import useLayout from "./useLayout";
import LoginConsumer from "src/services/login";
import userConsumer from "src/services/user";
import User from "src/models/User";
import Swal from "sweetalert2";
import { palette } from "src/theme";
import { Hash } from "src/functions/hash";

export default function useLogin() {
  const router = useNavigate();
  const { lang } = useParams();
  const location = useLocation();
  const { message, emptyMessage } = useLayout();

  const [loading, setLoading] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showMessage, setShowMessage] = useState(false);
  const [validationErrorPassword, setValidationErrorPassword] =
    useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [checkRemember, setCheckRemember] = useState<boolean>(false);
  const [code, setCode] = useState<string[]>(Array(6).fill(""));
  const [isRedirectScreenCode, setIsRedirectScreenCode] =
    useState<boolean>(false);

  const five = 5;
  const ten = 10;
  const sixty = 60;
  const thousand = 1000;
  const tenMinutes = ten * sixty * thousand;

  let lastInteractionTime = Date.now();

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleSendCredentials = (e: any) => {
    e.preventDefault();

    try {
      if (!Login.verifyPassword(e.target.password.value))
        return setValidationErrorPassword(true);

      setValidationErrorPassword(false);

      const data = {
        username: e.target.username.value,
        password: e.target.password.value,
      };

      sendCredentialsAndGetToken(data);
    } catch {
      setShowMessage(true);
    }
  };

  const getUserById = async (id: number) => {
    setLoading(true);

    try {
      const response = await userConsumer.getById(id);

      if (response.status !== 200) throw response;

      return response.data.value;
    } catch {
      setShowMessage(true);
    } finally {
      setLoading(false);
    }
  };

  const sendCredentialsAndGetToken = async (data: iLogin) => {
    try {
      setLoading(true);

      const body = {
        ...data,
        password: Hash({ password: `${data?.password}` }),
      };

      const response = await LoginConsumer.login(body);

      if (!response.data.acessToken) {
        const loginOrEmail = data?.email ? data?.email : data?.username;
        localStorage.setItem("loginOrEmail", `${loginOrEmail}`);
        return setIsRedirectScreenCode(true);
      }

      if (response.status === 400 || response.status === 404) {
        return setShowMessage(true);
      }

      if (response.status !== 200) throw response;

      saveDataLogin(response, data);
    } catch {
      setShowMessage(true);
    } finally {
      setLoading(false);
    }
  };

  const saveDataLogin = async (response: any, _: any) => {
    localStorage.setItem(
      localStorageStrings.apiToken,
      response.data.acessToken
    );

    localStorage.setItem(
      localStorageStrings.refreshToken,
      response.data.renewalToken
    );

    const user = await getUserById(response.data.userId);
    const userLoggeded = {
      ...user,
      senha: null,
      ativo: null,
      contaId: null,
      empresaId: null,
      telefone: null,
      isAdminEmpresa: null,
      isPrincipal: null,
      dataExpiracao: null,
      codigoAcesso: null,
    };

    localStorage.setItem(
      localStorageStrings.userLogged,
      User.adapterToString(userLoggeded)
    );

    getNewToken();
    router(`/${lang}/home`);
  };

  const logout = () => {
    localStorage.removeItem(localStorageStrings.apiToken);
    localStorage.removeItem(localStorageStrings.userLogged);
    localStorage.removeItem(localStorageStrings.reloaded);

    localStorage.clear();

    router(`/${lang}`);
  };

  const handleSetCheckRemember = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setCheckRemember(checked);
  };

  const getLoginUserInNavegatorMemory = () => {
    const dataUserSaveInNavigator = localStorage.getItem(
      localStorageStrings.dataLoginUserSalveInNavegator
    );
    if (dataUserSaveInNavigator) {
      const data = JSON.parse(dataUserSaveInNavigator);
      setUsername(data.username);
      setPassword(data.password);
      setCheckRemember(data.remember);
    }
  };

  const isLogged = () => {
    const userLogged = localStorage.getItem(localStorageStrings.userLogged);

    if (userLogged)
      User.adapterToJson(userLogged).id && router(`/${lang}/home`);
  };

  const getAvatarNameAppBar = () => {
    const userLogged = localStorage.getItem(localStorageStrings.userLogged);

    if (userLogged)
      return User.adapterToJson(userLogged).name.toLocaleUpperCase();

    return "Supply4 Med";
  };

  const checkActivity = () => {
    const currentTime = Date.now();
    const elapsedTime = currentTime - lastInteractionTime;

    if (elapsedTime <= sixty * thousand * five) {
      return true;
    }

    return false;
  };

  const refreshToken = async () => {
    if (!checkActivity()) {
      return logout();
    }

    const refreshToken = localStorage.getItem(localStorageStrings.refreshToken);
    const response = await LoginConsumer.refreshToken(`${refreshToken}`);

    if (response.status !== 200) {
      return logout();
    }

    localStorage.removeItem(localStorageStrings.apiToken);
    localStorage.removeItem(localStorageStrings.refreshToken);

    localStorage.setItem(
      localStorageStrings.apiToken,
      response.data.acessToken
    );

    const newDate = new Date();

    localStorage.setItem(
      localStorageStrings.hoursRefreshToken,
      `${newDate.getHours()}:${newDate.getMinutes()}`
    );

    localStorage.setItem(
      localStorageStrings.refreshToken,
      response.data.renewalToken
    );
    getNewToken();
  };

  const getNewToken = () => {
    const checkHours = () => {
      const now = new Date();

      if (now.getMinutes() * sixty * thousand >= tenMinutes) {
        clearInterval(checkInterval);
        refreshToken();
      }
    };

    const checkInterval = setInterval(checkHours, tenMinutes);
  };

  window.addEventListener(
    "mousemove",
    () => (lastInteractionTime = Date.now())
  );
  window.addEventListener("keydown", () => (lastInteractionTime = Date.now()));

  const handleChange = (
    index: number,
    value: string,
    loginExternal = false,
    token = ""
  ) => {
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    if (newCode.every((digit) => digit !== "")) {
      if (loginExternal) {
        loginUserExternal({
          code: newCode.join().replaceAll(",", ""),
          token: token,
        });
        return;
      }

      sendCode({
        code: newCode.join().replaceAll(",", ""),
        loginOrEmail: `${localStorage.getItem("loginOrEmail")}`,
      });
    }
  };

  const sendCode = async ({
    code,
    loginOrEmail,
  }: {
    code: string;
    loginOrEmail: string;
  }) => {
    setLoading(true);

    const data = {
      code,
      loginOrEmail,
    };

    try {
      const response = await LoginConsumer.validCode(data);

      if (
        response.status !== 200 &&
        response.status !== 201 &&
        response.status !== 204
      )
        throw response;

      Swal.fire({
        title: "Login realizado com sucesso",
        text: `Você será redirecionado para a página home!`,
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: palette.primary.main,
        confirmButtonText: "Ok",
      }).then((_) => {
        saveDataLogin(response, data);
        localStorage.removeItem("loginOrEmail");
      });
    } catch (e: any) {
      Swal.fire({
        title: "Ops...",
        text: `${
          e?.response?.data ??
          "Desculpe, ocorreu um erro, verifique se o código está correto!"
        }`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: palette.primary.main,
        confirmButtonText: "Ok",
      }).then((_) => {
        setCode(Array(6).fill(""));
      });
    } finally {
      setLoading(false);
    }
  };

  const loginUserExternal = async ({
    code,
    token,
  }: {
    code: string;
    token: string;
  }) => {
    setLoading(true);

    const data = {
      code,
      token,
    };

    const queryParams = new URLSearchParams(location.search);

    const processocompraid = queryParams.get("processocompraid");
    const empresaid = queryParams.get("empresaid");

    try {
      const response = await LoginConsumer.loginUserExternal(data);

      if (
        response.status !== 200 &&
        response.status !== 201 &&
        response.status !== 204
      )
        throw response;

      localStorage.setItem(
        localStorageStrings.apiToken,
        response.data.data.acessToken
      );

      localStorage.setItem(
        localStorageStrings.refreshToken,
        response.data.data.renewalToken
      );

      Swal.fire({
        title: "Login realizado com sucesso",
        text: `Você será redirecionado para o formulário!`,
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: palette.primary.main,
        confirmButtonText: "Ok",
      }).then((_) => {
        router(
          `/${lang}/shopping/process/demand/collection/form?processocompraid=${processocompraid}&empresaid=${empresaid}`
        );
      });
    } catch (e: any) {
      Swal.fire({
        title: "Ops...",
        text: `${
          e?.response?.data ??
          "Desculpe, ocorreu um erro, verifique se o código está correto!"
        }`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: palette.primary.main,
        confirmButtonText: "Ok",
      }).then((_) => {
        setCode(Array(6).fill(""));
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    validationErrorPassword,
    setValidationErrorPassword,
    handleSendCredentials,
    message,
    emptyMessage,
    showPassword,
    handleClickShowPassword,
    checkRemember,
    handleSetCheckRemember,
    username,
    setUsername,
    password,
    setPassword,
    getLoginUserInNavegatorMemory,
    logout,
    getUserById,
    isLogged,
    getAvatarNameAppBar,
    showMessage,
    setShowMessage,
    handleChange,
    code,
    setCode,
    isRedirectScreenCode,
    setIsRedirectScreenCode,
    loginUserExternal,
  };
}
