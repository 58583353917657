/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Layout from "src/components/Layout";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";
import { iBreadcrumbs } from "src/interfaces/breadcrumbs";
import {
  Autocomplete,
  Card,
  Checkbox,
  Divider,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  TextField,
  Grid,
  FormControl,
} from "@mui/material";
import { palette } from "src/theme";
import { MaskStyle } from "src/styles/globalStyle";
import {
  ButtonDefault,
  ButtonSalveForm,
  ButtonSearch,
} from "src/components/ButtonsCommons";
import TableComponent from "src/components/TableComponent";
import useInvoicing from "src/hooks/useInvoicing";
import CircularLoading from "src/components/Loading";
import { format } from "date-fns";
import Empty from "src/components/Empty";
import { formatCurrency } from "src/functions/text";

const CreateOrUpdateInvoicing: React.FC = () => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const breadcrumb: Array<iBreadcrumbs> = [
    {
      name: translations[param]["invoicing"],
    },
    {
      name: translations[param]["register"],
    },
  ];

  const header = [
    {
      label: translations[param]["number"],
    },
    {
      label: translations[param]["issuance_date"],
    },
    {
      label: translations[param]["buyer"],
    },
    {
      label: translations[param]["supplier"],
    },
    {
      label: translations[param]["payment_condition"],
    },
    {
      label: `${translations[param]["date"]} ${translations[param]["forecast"]} ${translations[param]["payment"]}`,
    },
    {
      label: `${translations[param]["value"]} NF`,
    },
    {
      label: translations[param]["rate"],
    },
    {
      label: "Valor a faturar",
    },
  ];

  const headerFlow = [
    {
      label: translations[param]["status"],
    },
    {
      label: translations[param]["date"],
    },
    {
      label: `${translations[param]["user"]} ${translations[param]["responsible"]}`,
    },
    {
      label: translations[param]["observation"],
    },
  ];

  const {
    page,
    setPage,
    handleChangePage,
    handleChangeRowsPerPage,
    status,
    setStatus,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    supplyer,
    setSupplyer,
    invoicingDate,
    setInvoicingDate,
    invoicingValue,
    setInvoicingValue,
    paymentCondition,
    setPaymentCondition,
    paymentDate,
    setPaymentDate,
    paymentValue,
    setPaymentValue,
    observations,
    setObservations,
    disableSupplier,
    getSuppliersWithoutInvocies,
    getPaymentConditionsAdministrator,
    paymentConditions,
    loading,
    supplyers,
    invoicesSupplyer,
    setInvoicesSupplyer,
    invoicesSupplyerSelected,
    setInvoicesSupplyerSelected,
    statusInvoicing,
    getStatusInvoicing,
    handleNewSalve,
    invoicingSelect,
    getStatusAndamento,
    adamentoStatus,
    cleanFilters,
    handleUpdate,
  } = useInvoicing();

  useEffect(() => {
    getPaymentConditionsAdministrator();
    getStatusInvoicing();
    getStatusAndamento(`${invoicingSelect?.id}`);
  }, []);

  return (
    <Layout
      buttons={[]}
      breadcrumbs={breadcrumb}
      callMenu={() => {
        cleanFilters();
        setPage({
          ...page,
          page: 0,
          total: 0,
        });
      }}
    >
      <Card sx={{ padding: 2, width: "100%" }}>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          display="flex"
          alignItems="center"
        >
          <Grid xs={12}>{translations[param]["invoicing"]}</Grid>
        </Grid>
        <Divider
          style={{
            backgroundColor: palette.primary.main,
            marginBottom: 20,
          }}
        />
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          display="flex"
          alignItems="center"
        >
          <Grid xs={12} sm={4}>
            <Grid
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FormLabel
                sx={{ minWidth: "45%", display: { xs: "none", sm: "block" } }}
              >
                {translations[param]["start_date"]}
              </FormLabel>
              <FormControl fullWidth>
                <MaskStyle>
                  <input
                    type="date"
                    name="begin_date"
                    placeholder="dd/mm/yyyy"
                    min="1970-01-01"
                    value={dateFrom}
                    onChange={(e) => setDateFrom(e.target.value)}
                  />
                </MaskStyle>
              </FormControl>
            </Grid>
          </Grid>
          <Grid xs={12} sm={4}>
            <Grid display="flex" alignItems="center">
              <FormLabel
                sx={{
                  minWidth: "5%",
                  display: { xs: "none", sm: "block" },
                  marginX: "5%",
                }}
              >
                {translations[param]["end_date"]}
              </FormLabel>
              <FormControl fullWidth>
                <MaskStyle>
                  <input
                    type="date"
                    name="begin_date"
                    placeholder="dd/mm/yyyy"
                    min="1970-01-01"
                    value={dateTo}
                    onChange={(e) => setDateTo(e.target.value)}
                  />
                </MaskStyle>
              </FormControl>
            </Grid>
          </Grid>
          <Grid xs={12} sm={4} display="flex" justifyContent="flex-end">
            <ButtonSearch
              label={translations[param]["search"]}
              size="medium"
              onClick={getSuppliersWithoutInvocies}
              disabled={loading || dateFrom === "" || dateTo === ""}
            />
          </Grid>
          <Grid xs={12} sx={{ marginTop: 1 }}>
            <Grid alignItems="center" display="flex">
              <FormLabel
                sx={{ minWidth: "15%", display: { xs: "none", sm: "block" } }}
              >
                {translations[param]["supplier"]}
              </FormLabel>
              <Autocomplete
                disablePortal
                fullWidth
                id="show_select_administrator_responsible"
                options={supplyers}
                value={supplyer}
                disabled={disableSupplier}
                getOptionLabel={(option: any) =>
                  option?.nomeFantasiaEmissor ?? ""
                }
                onChange={(_: any, value: any) => {
                  if (value?.idEmissor) {
                    setSupplyer(value);
                    setInvoicesSupplyer(value.notaFiscal);
                  } else {
                    setSupplyer(null);
                    setInvoicesSupplyer([]);
                  }
                }}
                renderInput={(params) =>
                  loading ? (
                    <CircularLoading />
                  ) : (
                    <TextField
                      {...params}
                      label="Selecione:"
                      size="small"
                      fullWidth
                    />
                  )
                }
              />
            </Grid>
          </Grid>
          <Grid xs={12} sm={4} sx={{ marginTop: 2 }}>
            <Grid container alignItems="center">
              <FormLabel
                sx={{ minWidth: "45%", display: { xs: "none", sm: "block" } }}
              >
                {translations[param]["invoicing_date"]}*
              </FormLabel>
              <MaskStyle>
                <input
                  type="date"
                  name="begin_date"
                  placeholder="dd/mm/yyyy"
                  min="1970-01-01"
                  value={invoicingDate}
                  required
                  onChange={(e) => setInvoicingDate(e.target.value)}
                />
              </MaskStyle>
            </Grid>
          </Grid>
          <Grid xs={12} sm={4}>
            <Grid alignItems="center" display="flex">
              <FormLabel
                sx={{ minWidth: "40%", display: { xs: "none", sm: "block" } }}
              >
                {translations[param]["value"]}{" "}
                {translations[param]["invoicing"]}*
              </FormLabel>
              <TextField
                size="small"
                id="value"
                name="value"
                label="R$ ##.###,00"
                variant="outlined"
                disabled
                sx={{ maxWidth: "75%" }}
                value={invoicingValue.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid xs={4} sm={4} md={4}>
            <Grid display="flex" alignItems="center">
              <FormLabel
                sx={{
                  minWidth: "50%",
                  display: { xs: "none", sm: "block" },
                  marginLeft: "5%",
                }}
              >
                {translations[param]["payment_condition"]}*
              </FormLabel>
              <Select
                labelId="show_select_type"
                id="show_select_status"
                value={paymentCondition}
                size="small"
                required
                onChange={(event: SelectChangeEvent) => {
                  setPaymentCondition(event.target.value);
                }}
                fullWidth
              >
                <MenuItem value="select" disabled>
                  {translations[param]["select"]}
                </MenuItem>
                <MenuItem value="">...</MenuItem>
                {paymentConditions?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.descricao}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <Grid xs={12} sm={4} sx={{ marginTop: 1 }}>
            <Grid container alignItems="center">
              <FormLabel
                sx={{ minWidth: "45%", display: { xs: "none", sm: "block" } }}
              >
                {translations[param]["date"]} {translations[param]["payment"]}
              </FormLabel>
              <MaskStyle>
                <input
                  type="date"
                  name="begin_date"
                  placeholder="dd/mm/yyyy"
                  min="1970-01-01"
                  value={paymentDate}
                  onChange={(e) => setPaymentDate(e.target.value)}
                />
              </MaskStyle>
            </Grid>
          </Grid>
          <Grid xs={12} sm={4}>
            <Grid display="flex" alignItems="center">
              <FormLabel
                sx={{ minWidth: "40%", display: { xs: "none", sm: "block" } }}
              >
                {translations[param]["value"]} {translations[param]["payment"]}
              </FormLabel>
              <TextField
                size="small"
                id="value"
                name="value"
                label="R$ ##.###,00"
                variant="outlined"
                sx={{ maxWidth: "75%" }}
                value={paymentValue}
                onChange={(e) => {
                  e = formatCurrency(e);
                  setPaymentValue(e.target.value);
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid xs={12} sm={4}>
            <Grid display="flex" alignItems="center">
              <FormLabel
                sx={{
                  minWidth: "50%",
                  display: { xs: "none", sm: "block" },
                  marginLeft: "5%",
                }}
              >
                {translations[param]["status"]}
              </FormLabel>
              <Select
                labelId="show_select_type"
                id="show_select_status"
                value={status}
                size="small"
                onChange={(event: SelectChangeEvent) =>
                  setStatus(event.target.value as string)
                }
                fullWidth
              >
                <MenuItem value="select" disabled>
                  Selecione:
                </MenuItem>
                <MenuItem value="">...</MenuItem>
                {statusInvoicing?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.descricao}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <Grid
            display="flex"
            justifyContent="end"
            sx={{ minWidth: "100%", marginTop: 2 }}
          >
            <Grid sx={{ minWidth: "53%" }}>
              <TextField
                id="observations"
                label={translations[param]["note_about_changing_status"]}
                multiline
                maxRows={4}
                value={observations}
                onChange={(e) => setObservations(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </Card>

      <Card sx={{ marginTop: "2%", padding: 2, width: "100%" }}>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          display="flex"
          alignItems="center"
        >
          <Grid xs={12}>{translations[param]["invoices"]}</Grid>
        </Grid>
        <Divider
          style={{
            backgroundColor: palette.primary.main,
            marginBottom: 20,
          }}
        />
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          display="flex"
          alignItems="center"
        >
          <Grid xs={12}>
            {invoicesSupplyer?.length === 0 ? (
              <Empty />
            ) : (
              <TableComponent
                header={header}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              >
                {invoicesSupplyer?.map((item) => (
                  <TableRow
                    hover
                    key={item.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell component="th" scope="row" onClick={() => {}}>
                      {item?.numero}
                    </TableCell>
                    <TableCell component="th" scope="row" onClick={() => {}}>
                      {item?.dataEmissao
                        ? format(new Date(item.dataEmissao), "dd/MM/yyyy")
                        : null}
                    </TableCell>
                    <TableCell component="th" scope="row" onClick={() => {}}>
                      {item?.razaoSocialTomador}
                    </TableCell>
                    <TableCell component="th" scope="row" onClick={() => {}}>
                      {item?.razaoSocialEmissor}
                    </TableCell>
                    <TableCell component="th" scope="row" onClick={() => {}}>
                      {item?.condicaoPagamento?.numeroDias} - Dias
                    </TableCell>
                    <TableCell component="th" scope="row" onClick={() => {}}>
                      {item.dataPrevisaoPagamento
                        ? format(
                            new Date(item.dataPrevisaoPagamento),
                            "dd/MM/yyyy"
                          )
                        : null}
                    </TableCell>
                    <TableCell component="th" scope="row" onClick={() => {}}>
                      {item?.valor?.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      }) ?? "0,00"}
                    </TableCell>
                    <TableCell component="th" scope="row" onClick={() => {}}>
                      {item?.taxa}%
                    </TableCell>
                    <TableCell component="th" scope="row" onClick={() => {}}>
                      {item?.valorFaturamentoS4M?.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      }) ?? "0,00"}
                    </TableCell>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={invoicesSupplyerSelected.includes(item)}
                        size="small"
                        onClick={() => {
                          if (!invoicesSupplyerSelected.includes(item)) {
                            const sum =
                              invoicingValue + item?.valorFaturamentoS4M;
                            setInvoicingValue(sum);

                            setInvoicesSupplyerSelected([
                              ...invoicesSupplyerSelected,
                              item,
                            ]);
                          } else {
                            const sum =
                              invoicingValue - item?.valorFaturamentoS4M;
                            setInvoicingValue(sum);

                            const newArray = invoicesSupplyerSelected?.filter(
                              (data) => data.id !== item.id
                            );
                            setInvoicesSupplyerSelected(newArray);
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableComponent>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          display="flex"
          alignItems="center"
          justifyContent="end"
        >
          <ButtonDefault label="Fechar" disabled={loading} onClick={() => {}} />
          {invoicingSelect?.id ? (
            <ButtonSalveForm
              label="Salvar"
              disabled={loading}
              onClick={handleUpdate}
            />
          ) : (
            <ButtonSalveForm
              label="Incluir"
              disabled={loading}
              onClick={handleNewSalve}
            />
          )}
        </Grid>
      </Card>

      <Card sx={{ marginTop: "2%", padding: 2, width: "100%" }}>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          display="flex"
          alignItems="center"
        >
          <Grid xs={12}>{translations[param]["billing_status_flow"]}</Grid>
        </Grid>
        <Divider
          style={{
            backgroundColor: palette.primary.main,
            marginBottom: 20,
          }}
        />
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          display="flex"
          alignItems="center"
        >
          <Grid xs={12}>
            {adamentoStatus?.length === 0 ? (
              <Empty />
            ) : (
              <TableComponent
                header={headerFlow}
                page={{
                  page: 0,
                  rowsPerPage: 10,
                  total: 10,
                }}
                handleChangePage={() => {}}
                handleChangeRowsPerPage={() => {}}
              >
                {adamentoStatus.map((item) => (
                  <TableRow
                    hover
                    key={item.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {item?.status}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item?.data
                        ? format(new Date(item.data), "dd/MM/yyyy")
                        : null}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item?.usuarioSistemaResponsavel}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item?.observacao}
                    </TableCell>
                  </TableRow>
                ))}
              </TableComponent>
            )}
          </Grid>
        </Grid>
      </Card>
    </Layout>
  );
};

export default CreateOrUpdateInvoicing;
